<template lang="pug">
.subscription-box-order-tracking
  h1 Rastreamento

  .tracking(v-if="tracking")

    .status
      b Transportadora:&#32;
      span {{ tracking.shippingCompany }}

    .status
      b Código de rastreamento:&#32;
      span {{ tracking.trackingCode }}

    ul.events(v-if="tracking.events.length > 0")

      li.event(v-for="event in tracking.events")
        .date
          b {{ toLocaleDateString(event.instant) }}

        .info
          b {{ event.description }}

          .movement(v-if="event.origin && event.destination")
            .location
              b De:&#32;
              span {{ locationDescription(event.origin) }}

            .location
              b Para:&#32;
              span {{ locationDescription(event.destination) }}

          .pickup-advice(v-if="event.detail") {{ event.detail }}

          .pickup-address(v-if="event.address")
            b Endereço:&#32;
            span {{ pickupAddress(event.address) }}

    .no-events(v-else)
      p Não existem informações de rastreamento para essa caixa.

      p.explanation {{ noEventExplanation }}

  .correios-tracking(v-else-if="order && order.shippingCompany == 'CORREIOS' && order.trackingCode")
    iframe(
      :src="`https://portalpostal.com.br/sro.jsp?sro=${order.trackingCode}`",
      frameborder="0",
      scrolling="auto",
      width="100%",
      height="600",
    )


  .no-tracking(v-else)
    | Não foi possível encontrar informações de rastreamento para essa caixa.

</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'

import type { SubscriptionBoxOrderTrackingEventLocation, SubscriptionBoxOrderTrackingAddress } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import SubscriptionBoxOrderService from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'


definePageMeta({
  requiresAuth: true
})

const dayjs = useDayjs()
const route = useRoute()

const orderId = parseInt(route.params.orderId as string)

const { data: order } = await useAsyncData(() => {
  return SubscriptionBoxOrderService.findOrder(orderId)
})

const { data: tracking, pending: loading, error } = await useAsyncData(() => {
  return SubscriptionBoxOrderService.findTracking(orderId)
    .catch((error: FetchError) => {
      if (error.status === 404) {
        return null
      }

      throw error
    })
  })

reportOnError(error, 'Failed to load subscription box tracking info')

const noEventExplanation = computed(() => {
  if (tracking.value?.events?.length ?? 0 == 0)  {
    return ''
  }

  switch(tracking.value?.status) {
    case 'DELIVERED':
    case 'RETURNED':
    case 'MISPLACED':
      return 'Caixas que foram enviadas há muito tempo não são mais rastreáveis.'
    default:
      return 'Pode levar até 1 dia útil após o envio para que as informações de rastreamento sejam adicionadas.'
  }
})

const locationDescription = (location: SubscriptionBoxOrderTrackingEventLocation) => {
  const city = location.city ? `${location.city}/` : ''

  return `${location.location} - ${city}${location.state}`
}

const pickupAddress = (address: SubscriptionBoxOrderTrackingAddress) => {
  const complement = address.complement ? `, ${address.complement}` : ''

  return `${address.street}, ${address.number ?? 'S/N'}${complement} - ${address.district} - ${address.city} - ${address.state}`
}

const toLocaleDateString = (date: string) => {
  return dayjs(date).format('DD/MM')
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.subscription-box-order-tracking
  position: relative
  max-width: 936px
  margin: 0 auto
  padding: 0 8px
  text-align: left
  animation: fade-in .2s forwards

  ul.events
    list-style: none
    padding-left: 0

    li.event
      position: relative
      display: flex
      flex-direction: row
      gap: 8px
      align-items: stretch
      margin: 0
      padding: 8px 0

      .date
        width: 56px
        padding-right: 8px
        border-right: 2px solid $beige

      .info
        flex: 1

        .movement
          margin: 16px 0

          &:last-child
            margin-bottom: 0

        .pickup-advice
          margin: 16px 0

      &:last-child

        &::before
          height: 8px

</style>
